<template>
  <div>
    <div class="pnl_itm_title">
      <div v-if="!hasTabs" class="pnl_itm_title_txt active">Distribution</div>
      <template v-else>
        <div
          v-for="(tab, i) in tabOptions"
          :key="i"
          class="pnl_itm_title_txt"
          :class="{ active: tab.id === selectedTab }"
          @click="onTabClick(tab)"
        >
          {{ tab.value }}
        </div>
      </template>
      <div class="pnl_title_right">
        <div class="pnl_menu_btn" @click="handleExportClick">
          <font-awesome-icon :icon="['fas', 'arrow-down-to-line']" />
        </div>
      </div>
    </div>
    <div class="sm_pnl_subtop_wrap" style="margin-bottom: 13px">
      <div v-if="!hideDimensionOptions && !hasMultiLevelDimensions" class="sm_pnl_subtop sbt_left">
        <b-dropdown-flat
          :options="dimensionOptions"
          component-id="dimensionOptionsDropdown"
          @on-change="onSelectDimension"
        />
      </div>
      <div v-if="!hideDimensionOptions && hasMultiLevelDimensions" class="sm_pnl_subtop sbt_left">
        <b-dropdown-multilevel
          :show-option-with-icon="true"
          :options="dimensionOptions"
          component-id="dimensionOptionsDropdown"
          @on-change="onSelectDimension"
        />
      </div>
      <div v-if="!hideDimensionOptions && !hasMultiLevelMetrics" class="sm_pnl_subtop sbt_right">
        <b-dropdown-flat
          :options="metricOptions"
          component-id="metricOptionsDropdown"
          @on-change="onSelectMetric"
        />
      </div>
      <div v-if="!hideDimensionOptions && hasMultiLevelMetrics" class="sm_pnl_subtop sbt_right">
        <b-dropdown-multilevel
          :options="metricOptions"
          :allow-multi-select-children="true"
          :show-all="false"
          component-id="metricOptionsDropdown"
          @on-change="onSelectMetric"
          @selected-options="onSelectMetrics"
        />
      </div>
      <div
        v-if="hideDimensionOptions && !hasMultiLevelMetrics"
        class="sm_pnl_subtop"
        style="width: 100%"
      >
        <b-dropdown-flat
          :options="metricOptions"
          component-id="metricOptionsDropdown"
          @on-change="onSelectMetric"
        />
      </div>
      <div
        v-if="hideDimensionOptions && hasMultiLevelMetrics"
        class="sm_pnl_subtop"
        style="width: 100%"
      >
        <b-dropdown-multilevel
          :options="metricOptions"
          :allow-multi-select-children="true"
          :show-all="false"
          component-id="metricOptionsDropdown"
          @on-change="onSelectMetric"
          @selected-options="onSelectMetrics"
        />
      </div>
    </div>
    <donut-chart
      v-if="!loading && columns.length"
      :columns="columns"
      :value-type="valueFormat"
      :height="donutSize"
      :width="donutSize"
      :component-id="donutComponentId"
    />
    <div v-if="!loading && columns.length" class="sol_legend">
      <div :id="tooltipId" class="panel_scroll_wrap" style="padding-right: 0px;max-height: 160px;">
        <div v-for="(row, i) in rows" :key="i" class="sol_leg_item">
          <div class="sol_leg_clr" :style="`background-color: ${COLOR_PATTERN[i]};`"></div>
          <div
            :ref="`${i}_metadata`"
            class="sol_leg_title"
            @mouseover="() => handleMouseOver(i, row.key)"
            @mouseleave="handleMouseLeave"
          >
            {{ row.key }}
          </div>
          <div class="sol_leg_value">
            {{ formatNumber(row.value, valueFormat, decimals) }}
          </div>
        </div>
      </div>
      <b-tooltip
        :tooltip-text="`${getFullLegendName(rows[activeEl.index])}`"
        :visible-style="activeEl.style"
      />
    </div>
    <div v-if="!loading && !columns.length" class="txt-center">
      No data is available to show
    </div>
    <b-loading-spinner v-if="loading" class="txt-center" />
  </div>
</template>
<script>
import { uuid } from '@/helpers/global/misc-helpers';
import { DISTRIBUTION_DIMENSION_TITLES, COLOR_PATTERN } from '@/constant';
import BDropdownFlat from '~/components/elements/b-dropdown-flat.vue';
import BTooltip from '~/components/elements/b-tooltip.vue';
import * as util from '~/util/utility-functions';
import formatNumber from '~/util/numeral';

import BDropdownMultilevel from './elements/b-dropdown-multilevel.vue';

export default {
  components: {
    BDropdownFlat,
    BTooltip,
    DonutChart: () =>
      import(/* webpackChunkName: "donut-chart" */ '~/components/charts/donut-chart.vue'),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    BDropdownMultilevel,
  },
  props: {
    dimensionOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    metricOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    rows: {
      type: Array,
      required: true,
    },
    tabOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    dimension: {
      type: String,
      required: false,
      default: () => '',
    },
    hideDimensionOptions: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    donutSize: {
      type: Number,
      required: false,
      default: () => 190,
    },
    donutComponentId: {
      type: String,
      required: false,
      default: () => 'sChart',
    },
  },
  data() {
    let selectedDimension = '';
    if (this.dimensionOptions?.length) {
      const defaultSelectedDimension = this.dimensionOptions.find((o) => o.selected);
      const hasChildren = defaultSelectedDimension?.children?.length;
      if (hasChildren) {
        const defaultSelectedDimensionChild = defaultSelectedDimension.children.find(
          (o) => o.selected
        );
        selectedDimension =
          defaultSelectedDimensionChild?.id || defaultSelectedDimension.children[0].id;
      } else {
        selectedDimension = defaultSelectedDimension?.id || this.dimensionOptions[0].id;
      }
    }

    const selectedMetric = this.metricOptions?.length
      ? this.metricOptions[0].id || this.metricOptions[0].toLowerCase()
      : '';
    const selectedMetricOption = this.metricOptions?.find((o) => o.id === selectedMetric);
    let selectedChildMetrics = [];
    if (selectedMetricOption?.children?.length) {
      selectedChildMetrics = selectedMetricOption.children
        .filter((c) => c.selected)
        .map((c) => c.id);
    }

    let selectedTab = '';
    if (this.tabOptions?.length) {
      const defaultSelectedTab = this.tabOptions.find((o) => o.selected);
      selectedTab = defaultSelectedTab?.id || this.tabOptions[0].id;
    }

    return {
      decimals: '0',
      selectedDimension,
      selectedMetric,
      selectedChildMetrics,
      selectedTab,
      formatNumber,
      COLOR_PATTERN,
      activeEl: { style: { display: 'none', visibility: 'hidden' } },
      tooltipId: uuid(),
    };
  },
  computed: {
    hasTabs() {
      return this.tabOptions.length;
    },
    valueFormat() {
      return util.getValueFormat(this.selectedMetric);
    },
    columns() {
      const nColumns = [];
      this.rows.forEach((r) => {
        nColumns.push([r?.key ? r?.key?.replaceAll(',', ' ') : '  ', r.value]);
      });
      return nColumns;
    },
    hasMultiLevelDimensions() {
      return this.dimensionOptions.find((o) => o.children && o.children.length);
    },
    hasMultiLevelMetrics() {
      return this.metricOptions.find((o) => o.children && o.children.length);
    },
  },
  watch: {
    tabOptions(newTabOptions) {
      this.selectedTab = '';
      if (newTabOptions?.length) {
        const defaultSelectedTab = newTabOptions.find((t) => t.selected);
        this.selectedTab = defaultSelectedTab?.id || newTabOptions[0].id;
      }
    },
    dimensionOptions(newDimensionOptions) {
      this.selectedDimension = '';
      if (newDimensionOptions?.length) {
        const defaultSelectedDimension = newDimensionOptions.find((o) => o.selected);
        const hasChildren = defaultSelectedDimension?.children?.length;
        if (hasChildren) {
          const defaultSelectedDimensionChild = defaultSelectedDimension.children.find(
            (o) => o.selected
          );
          this.selectedDimension =
            defaultSelectedDimensionChild?.id || defaultSelectedDimension.children[0].id;
        } else {
          this.selectedDimension = defaultSelectedDimension?.id || newDimensionOptions[0].id;
        }
      }
    },
    metricOptions(newMetricOptions) {
      this.selectedMetric = newMetricOptions?.length
        ? newMetricOptions[0].id || newMetricOptions[0].toLowerCase()
        : '';
      const selectedMetricOption = newMetricOptions?.find((o) => o.id === this.selectedMetric);
      this.selectedChildMetrics = [];
      if (selectedMetricOption?.children?.length) {
        this.selectedChildMetrics = selectedMetricOption.children
          .filter((c) => c.selected)
          .map((c) => c.id);
      }
    },
  },
  methods: {
    getFullLegendName(name) {
      if (name) {
        return name.key;
      }
      return '';
    },
    handleMouseOver(i, text = '') {
      const ulElement = document.getElementById(this.tooltipId);
      // const elementPos = this.$refs[refKey][0];
      // if (!(elementPos.offsetWidth < elementPos.scrollWidth)) {
      //   return;
      // }
      let moveTooltip = 0;
      const numberOfLines = Math.ceil(text?.length / 197);
      if (numberOfLines > 1) {
        moveTooltip = 15 * numberOfLines;
      }
      this.activeEl = {
        style: {
          top: `${(i - 0.5) * 30 - moveTooltip - ulElement.scrollTop + i}px`,
          left: `${50}px`,
          display: 'block',
          opacity: '0.96',
          visibility: 'visible',
          'max-width': '1000px',
          width: 'max-content',
        },
        index: i,
      };
    },
    handleMouseLeave() {
      this.activeEl = { style: { display: 'none', visibility: 'hidden' }, index: -1 };
    },
    getDimensionForExport() {
      let dimension = this.selectedDimension;
      if (this.hideDimensionOptions) {
        if (this.hasTabs) {
          dimension = this.selectedTab || '';
        } else {
          dimension = this.dimension;
        }
      }
      dimension = DISTRIBUTION_DIMENSION_TITLES[dimension] || dimension;
      return dimension;
    },
    handleExportClick() {
      const dimension = this.getDimensionForExport();
      const header = [dimension, this.selectedMetric];
      const lines = [...this.columns];
      const csvData = [header, ...lines];
      const fileName = `export_${this.selectedMetric}_by_${dimension}.csv`;
      this.$emit('on-export', fileName, csvData);
    },
    onSelectDimension(option) {
      this.selectedDimension = option;
      this.$emit(
        'on-options-change',
        this.selectedTab,
        this.selectedDimension,
        this.selectedMetric,
        this.selectedChildMetrics
      );
    },
    onSelectMetric(option) {
      this.selectedMetric = option;
      this.$emit(
        'on-options-change',
        this.selectedTab,
        this.selectedDimension,
        this.selectedMetric,
        ''
      );
    },
    onSelectMetrics(options) {
      this.selectedChildMetrics = [];
      options.forEach((opt) => {
        if (opt.selected) {
          this.selectedMetric = opt.id;
          this.selectedChildMetrics = [];
          if (opt.children && opt.children.length) {
            const selectedChildIds = opt.children.filter((c) => c.selected).map((c) => c.id);
            if (selectedChildIds) {
              this.selectedChildMetrics.push(...selectedChildIds);
            }
          }
        }
      });

      this.$emit(
        'on-options-change',
        this.selectedTab,
        this.selectedDimension,
        this.selectedMetric,
        this.selectedChildMetrics
      );
    },
    onTabClick(tab) {
      this.selectedTab = tab.id;
      this.$emit(
        'on-options-change',
        this.selectedTab,
        this.selectedDimension,
        this.selectedMetric
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.pnl_itm_title {
  border-bottom: 1px solid #e0e5ee;
  padding-bottom: 0px;
  white-space: nowrap;
}

.pnl_itm_title_txt {
  display: inline-block;
  position: relative;
  border-bottom: 0px solid;
  padding-bottom: 15px;
  top: 2px;
  z-index: 9;
  color: var(--primarylight);
  font-size: 1.02em;
  font-weight: 600;
  margin-right: 25px;
  cursor: pointer;
}
.pnl_itm_title_txt:hover {
  color: var(--primarydark);
}
.pnl_itm_title_txt.active {
  display: inline-block;
  position: relative;
  border-bottom: 3px solid var(--primarycolor);
  padding-bottom: 15px;
  top: 2px;
  z-index: 9;
  font-weight: 600;
  color: var(--primarydark);
}

.pnl_title_right {
  display: inline-block;
  width: 250px;
  text-align: right;
  float: right;
  margin-top: -6px;
  position: relative;
}
.one-thirds .pnl_title_right {
  width: 30px;
}

.pnl_menu_btn {
  display: inline-block;
  position: relative;
  vertical-align: top;
  padding: 7px;
  margin-right: -7px;
  font-size: 16px;
  color: var(--primarylight);
}
.pnl_menu_btn:hover {
  color: var(--primarycolor);
}

/*small panel under title dropdown*/
.sm_pnl_subtop {
  margin: 0px;
  vertical-align: top;
}

.sm_pnl_subtop_wrap {
  border-bottom: 1px solid #e4e6ec;
  margin-bottom: 0px;
  white-space: nowrap;
}

::v-deep .sm_pnl_subtop .dropdown,
::v-deep .sm_pnl_subtop .multiselect-dropdown {
  width: 100%;
  max-width: 100%;
  min-width: auto;
  border: 0px;
  border-radius: 0px;
  margin-bottom: 0px;
  vertical-align: bottom;
  font-size: 0.93em;
}
::v-deep .sm_pnl_subtop .dropdown .select,
::v-deep .sm_pnl_subtop .multiselect-dropdown .select {
  padding: 9px 20px 9px 1px;
}
::v-deep .sm_pnl_subtop .dropdown .select:focus-visible,
::v-deep .sm_pnl_subtop .multiselect-dropdown .select:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
  outline: none;
}

::v-deep .sm_pnl_subtop .dropdown:focus-visible,
::v-deep .sm_pnl_subtop .multiselect-dropdown:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
  outline: none;
}

::v-deep .sm_pnl_subtop .droparw {
  right: inherit;
  margin-left: 8px;
}

.sbt_right {
  display: inline-block;
  width: 49%;
}
.sbt_left {
  display: inline-block;
  width: 49%;
}
::v-deep .sm_pnl_subtop .dropdown.rt .select {
  padding: 9px 30px 9px 10px;
}
::v-deep .dropdown.rt .dropdown-menu li,
::v-deep .dropdown.rt .dropdown-menu-multi li {
  padding: 5px 20px 5px 10px;
}

::v-deep .sbt_left .droparw {
  right: inherit;
  margin-left: 8px;
}

::v-deep .sbt_left .top-span {
  margin-right: 10px;
}

::v-deep .sbt_right .dropdown,
::v-deep .sbt_right .multiselect-dropdown {
  text-align: right;
}

::v-deep .sbt_right .dropdown .select,
::v-deep .sbt_right .multiselect-dropdown .select {
  padding: 9px 0px 9px 1px;
}
::v-deep .sbt_right .dropdown .dropdown-menu li,
::v-deep .sbt_right .dropdown .dropdown-menu-multi li {
  padding: 5px 20px 5px 10px;
}

::v-deep .sbt_right .dropdown .dropdown-menu li.dropdown-content-selected .norm-option-item {
  width: 100%;
}

::v-deep .sbt_right .multiselect-dropdown .dropdown-container-menu li .norm-option-item {
  text-align: left;
}

.sbm_i {
  margin-left: 8px;
  margin-right: 4px;
}

::v-deep .sm_pnl_subtop.sbt_right .dropdown .dropdown-menu-multi {
  min-width: 180px;
  margin-left: -54px;
  text-align: left;
}

::v-deep .sm_pnl_subtop.sbt_right .dropdown-menu-multi .mdrp-sub-itms .drop-ck {
  left: 17px;
  top: 11px;
}
.sm_pnl_subtop.sbt_right .mdrp-sub {
  padding: 8px 9px 8px 40px !important;
}

.sm_pnl_subtop .subtop_title_txt {
  font-size: 13px;
  font-weight: 500;
  color: var(--primarydark2);
  padding: 9px 10px;
  vertical-align: top;
}
.sm_pnl_subtop.sbt_right .subtop_title_txt {
  text-align: right;
}

#sChart {
  width: 100%;
  margin: auto;
  margin-top: -8px;
  margin-left: 10px;
  left: -9px;
  text-align: center;
}
.sol_legend {
  position: relative;
}
#sChartsLargeChart {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  margin-left: 10px;
  left: -9px;
  text-align: center;
  max-height: 240px;
  position: relative;
  bottom: 2px;
  margin-bottom: 6px;
}

.panel_scroll_wrap {
  overflow-y: scroll;
  height: 100%;
  padding-right: 10px;
  overflow-x: hidden;
}

.sol_leg_item {
  font-size: 13px;
  padding: 5px 0px;
}

.sol_leg_clr {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: #ddd;
  border-radius: 4px;
  margin-right: 11px;
  position: relative;
  vertical-align: middle;
}

.sol_leg_title {
  display: inline-block;
  width: calc(100% - 100px);
  font-weight: 700;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sol_leg_value {
  display: inline-block;
  font-weight: 700;
  width: 67px;
  text-align: right;
  vertical-align: middle;
}
</style>
