<template>
  <div class="bdc_main_wrap whero">
    <div v-if="tableauJwtToken" class="thw_content">
      <div class="internal-sub-nav">
        <div
          v-for="(tab, i) in tabOptions"
          :key="i"
          class="isn-item"
          :class="{ active: tab.id === selectedTab, disabled: tab.disabled }"
          @click="tabOptions.length === 1 ? null : onTabClick(tab)"
        >
          {{ tab.value }}
        </div>
      </div>
    </div>

    <div v-if="tableauJwtToken && !['overview'].includes(selectedTab)" class="bdc_top_panel_wrap">
      <tableau-viz id="tableauViz" :src="tableauViewURL" :token="tableauJwtToken" toolbar="hidden">
        <viz-filter field="Client ID" :value="accountId"></viz-filter>
        <viz-filter field="Advertiser ID" :value="advertiserId"></viz-filter>
        <viz-filter field="Start Date" :value="startDate"></viz-filter>
        <viz-filter field="End Date" :value="endDate"></viz-filter>
        <viz-filter field="Days To Convert" :value="conversionWindow"></viz-filter>
        <viz-filter field="Ad Ready ID List" :value="adReadyIdList"></viz-filter>
        <viz-parameter field="embed" value="yes"></viz-parameter>
      </tableau-viz>
    </div>
    <div v-if="selectedTab === 'overview'" class="overviewContainer">
      <div class="vennContainer">
        <div class="expandContainer"></div>
        <div class="vennHeader">
          Linear / CTV Overlap
          <div class="exportIcon"></div>
        </div>
        <BLoadingSpinner v-if="dataLoading" class="txt-center" />
        <div v-else id="venn" class="venn" />
      </div>
      <div class="tableContainer">
        <div class="expandContainer"></div>
        <div class="tableHeader">
          Overview
          <div class="exportIcon"></div>
        </div>
        <BLoadingSpinner v-if="dataLoading" class="txt-center" />
        <div v-else class="overviewTable">
          <table>
            <thead>
              <tr class="row1">
                <th></th>
                <th>Unique Reach %</th>
                <th>Incremental Reach %</th>
                <th>Conversion Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="col1">CTV</td>
                <td>{{ ctvUniqueReachPercent ? ctvUniqueReachPercent + ' %' : '-' }}</td>
                <td>{{ ctvIncrementalReachPercent ? ctvIncrementalReachPercent + ' %' : '-' }}</td>
                <td>{{ ctvConversionRate ? ctvConversionRate + ' %' : '-' }}</td>
              </tr>
              <tr>
                <td class="col1">Linear</td>
                <td>{{ linearUniqueReachPercent ? linearUniqueReachPercent + ' %' : '-' }}</td>
                <td>{{ '-' }}</td>
                <td>{{ linearConversionRate ? linearConversionRate + ' %' : '-' }}</td>
              </tr>
              <tr>
                <td class="col1">Overlap</td>
                <td>{{ '-' }}</td>
                <td>{{ '-' }}</td>
                <td>{{ overlapConversionRate ? overlapConversionRate + ' %' : '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'underscore';
import { get } from 'vuex-pathify';
import axios from 'axios';
import * as d3 from 'd3';
import * as venn from 'venn.js';
import appConsoleDataLayer from '~/api/app-console-data';
import BLoadingSpinner from '~/components/elements/b-loading-spinner.vue';
import {
  OVERVIEW_TAB_OPTIONS,
  DELIVERY_TAB_OPTIONS,
  PERFORMANCE_TAB_OPTIONS,
} from '~/converged-tv-constant';
import { formatDateForAPI } from '~/util/apiDateFormat';
import config from '~/config';
import { isDemoInstance } from '~/util/utility-functions';

export default {
  components: {
    BLoadingSpinner,
  },
  props: {
    activeSolutionId: {
      type: Number,
      required: false,
      default: 1,
    },
    activeDashboard: {
      type: Object,
      required: false,
      default: () => {},
    },
    isLeftNavCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    let tabOptions = OVERVIEW_TAB_OPTIONS;

    if (this.activeDashboard?.title === 'Delivery & Engagement') {
      tabOptions = DELIVERY_TAB_OPTIONS;
    } else if (this.activeDashboard?.title === 'Performance') {
      tabOptions = PERFORMANCE_TAB_OPTIONS;
    } else {
      tabOptions = OVERVIEW_TAB_OPTIONS;
    }

    let selectedTab = '';
    let selectedTabTitle = '';
    let selectedTabUrl = '';
    if (tabOptions?.length) {
      const defaultSelectedTab = tabOptions.find((t) => t.selected) || this.tabOptions[0];
      selectedTab = defaultSelectedTab?.id || tabOptions[0].id;
      selectedTabTitle = defaultSelectedTab?.value || tabOptions[0].value;
      if (isDemoInstance()) {
        selectedTabUrl = defaultSelectedTab?.demoViewUrl || tabOptions[0].demoViewUrl;
      } else {
        selectedTabUrl = defaultSelectedTab?.viewUrl || tabOptions[0].viewUrl;
      }
    }
    const vennData = '';

    return {
      tabOptions,
      selectedTab,
      selectedTabTitle,
      selectedTabUrl,
      vennData,
      tableauJwtToken: null,
      dataLoading: false,
      isVennExpanded: false,
      isTableExpanded: false,
    };
  },
  computed: {
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    dates: get('dashboard/dates'),
    conversionWindow: get('dashboard/filters@conversionWindow'),
    filtersApplied: get('dashboard/filtersApplied'),
    selectedCampaignIds: get('dashboard/GET_SELECTED_CAMPAIGN_IDS'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    adReadyIdList() {
      const { adGroupOptions } = this;
      if (adGroupOptions.length === 0) {
        return '';
      }

      const selectedAdGroupOptions = adGroupOptions.filter((f) => f.checked);
      if (selectedAdGroupOptions.length >= 0) {
        let queryStrArray = [];
        selectedAdGroupOptions.forEach((adGroup) => {
          const { adreadyIds } = adGroup;
          adreadyIds.forEach((lid) => {
            queryStrArray.push(lid.adreadyId);
          });
        });
        queryStrArray = queryStrArray.filter(
          (item, index) => queryStrArray.indexOf(item) === index
        );
        return queryStrArray.join('\\,');
      }
      return '';
    },
    advertiserId() {
      return this.advertiser ? this.advertiser.id : 0;
    },
    advertiserName() {
      return this.advertiser ? this.advertiser.name : 0;
    },
    accountId() {
      return this.account ? this.account.id : 0;
    },
    accountName() {
      return this.account ? this.account.name : 0;
    },
    startDate() {
      return formatDateForAPI(this.dates.startDate);
    },
    endDate() {
      return formatDateForAPI(this.dates.endDate);
    },
    campaignIds() {
      if (this.selectedCampaignIds.length) {
        const campaignIdString = this.selectedCampaignIds[0];
        const campaignIdArray = campaignIdString.split(',');
        const campaignIdStringArray = campaignIdArray.map((item) => `'${item}'`);
        const campaignId = campaignIdStringArray.join(',');
        return campaignId;
      }
      return '';
    },
    tableauViewURL() {
      return `${config.TABLEAU_URL}${this.selectedTabUrl}`;
    },
    ctvUniqueReachPercent() {
      const { overallCtvReach, linearAndCtvNrpReach } = this.vennData;
      const ctvUniqueReachPercent =
        ((overallCtvReach - linearAndCtvNrpReach) / overallCtvReach) * 100;
      if (Number.isNaN(ctvUniqueReachPercent)) return null;
      return Number(ctvUniqueReachPercent).toFixed(2);
    },
    ctvIncrementalReachPercent() {
      const { overallCtvReach, linearAndCtvNrpReach, overallLinearNrpReach } = this.vennData;
      const ctvIncrementalReachPercent =
        ((overallCtvReach - linearAndCtvNrpReach) / overallLinearNrpReach) * 100;
      if (Number.isNaN(ctvIncrementalReachPercent)) return null;
      return Number(ctvIncrementalReachPercent).toFixed(2);
    },
    ctvConversionRate() {
      const { ctvCvr } = this.vennData;
      const ctvConversionRate = ctvCvr;
      return Number(ctvConversionRate).toFixed(4);
    },
    linearUniqueReachPercent() {
      const { overallLinearNrpReach, linearAndCtvNrpReach } = this.vennData;
      const linearUniqueReachPercent =
        ((overallLinearNrpReach - linearAndCtvNrpReach) / overallLinearNrpReach) * 100;
      if (Number.isNaN(linearUniqueReachPercent)) return null;
      return Number(linearUniqueReachPercent).toFixed(2);
    },
    linearConversionRate() {
      const { linearCvr } = this.vennData;
      const linearConversionRate = linearCvr;
      return Number(linearConversionRate).toFixed(4);
    },
    overlapConversionRate() {
      const { linearAndCtvOverlapCvr } = this.vennData;
      const overlapConversionRate = linearAndCtvOverlapCvr;
      return Number(overlapConversionRate).toFixed(4);
    },
    sets() {
      return [
        {
          sets: ['Linear'],
          label: 'Linear',
          size:
            this.vennData && this.vennData.overallLinearNrpReach
              ? Number(this.vennData.overallLinearNrpReach)
              : 0,
        },
        {
          sets: ['CTV'],
          label: 'CTV',
          size:
            this.vennData && this.vennData.overallCtvReach
              ? Number(this.vennData.overallCtvReach)
              : 0,
        },
        {
          sets: ['Linear', 'CTV'],
          label: `Overlap`,
          size:
            this.vennData && this.vennData.linearAndCtvNrpReach
              ? Number(this.vennData.linearAndCtvNrpReach)
              : 0,
        },
      ];
    },
    payload() {
      return {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        campaignIds: this.campaignIds,
        adGroups: this.adReadyIdList,
        startDate: this.startDate,
        endDate: this.endDate,
        conversionWindow: this.conversionWindow * 24,
      };
    },
  },
  watch: {
    advertiserId: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
          if (this.selectedTab === 'overview') {
            this.vennData = await this.fetchData();
            this.updateVenn();
          }
        }
      },
    },
    accountId: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
          if (this.selectedTab === 'overview') {
            this.vennData = await this.fetchData();
            this.updateVenn();
          }
        }
      },
    },
    dates: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
          if (this.selectedTab === 'overview') {
            this.vennData = await this.fetchData();
            this.updateVenn();
          }
        }
      },
    },
    filtersApplied: {
      deep: true,
      async handler(n) {
        if (n) {
          this.$store.set('dashboard/filtersApplied', false);
          this.$store.set('dashboard/filtersAppliedLoading', false);
          this.debounceTableauAuthToken();
          if (this.selectedTab === 'overview') {
            this.vennData = await this.fetchData();
            this.updateVenn();
          }
        }
      },
    },
    payload: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (this.selectedTab === 'overview') {
            this.vennData = await this.fetchData();
            this.updateVenn();
          }
        }
      },
    },
    selectedTab: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (this.selectedTab === 'overview') {
            this.vennData = await this.fetchData();
            this.updateVenn();
          }
        }
      },
    },
  },
  async mounted() {
    this.$store.set('dashboard/selectedDashboardTab', this.tabOptions[0].id);
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      `${config.TABLEAU_URL}/javascripts/api/tableau.embedding.3.latest.js`
    );
    recaptchaScript.setAttribute('type', 'module');
    document.head.appendChild(recaptchaScript);
    this.getTableauAuthToken();
    if (this.selectedTab === 'overview') {
      this.vennData = await this.fetchData();
      this.updateVenn();
    }
  },
  methods: {
    debounceTableauAuthToken() {
      _.debounce(this.getTableauAuthToken(), 100, true);
    },
    async getTableauAuthToken() {
      axios
        .get(`${config.ADREADY_URL}/api/token/tableau`, {
          withCredentials: true,
        })
        .then((d) => {
          this.tableauJwtToken = d.data.result;
        });
    },
    async onTabClick(tab) {
      if (tab.disabled) {
        return;
      }
      this.selectedTab = tab.id;
      this.selectedTabTitle = tab.value;
      if (isDemoInstance()) {
        this.selectedTabUrl = tab.demoViewUrl;
      } else {
        this.selectedTabUrl = tab.viewUrl;
      }
      this.$store.set('dashboard/selectedDashboardTab', tab.id);
      this.debounceTableauAuthToken();
      if (this.selectedTab === 'overview') {
        this.vennData = await this.fetchData();
        // this.updateVenn();
        // this.updateLabelPosition();
      }
    },
    updateVenn() {
      if (this.selectedTab !== 'overview') return;
      const chart = venn
        .VennDiagram()
        .width(450)
        .height(450);

      // Custom ordering
      const customOrdering = { CTV: 1, Linear: 2 };
      chart.orientationOrder(function(a, b) {
        return customOrdering[a.setid] - customOrdering[b.setid];
      });

      const colours = ['#5856FD', '#016887'];
      if (!this.sets.length) return;

      const vennChart = d3
        .select('#venn')
        .datum(this.sets)
        .call(chart);
      const tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'venntooltip');

      // Custom path colors
      d3.selectAll('#venn .venn-circle path')
        .style('fill-opacity', 0.4)
        .style('fill', function(d, i) {
          return colours[i];
        })
        .style('stroke-width', 0.1)
        .style('stroke-opacity', 0.4)
        .style('stroke', function(d, i) {
          return colours[i];
        });

      // Custom text colors
      d3.selectAll('#venn .venn-circle text').style('fill', function(d, i) {
        return colours[i];
      });

      // Adjust positino of 'Overlap' label
      // const yVal = d3.selectAll('#venn tspan').attr('y', function(d) {
      //   if (d.label === 'Overlap') {
      //     console.log(d);
      //     console.log('d.y: ', d.__proto);
      //   }
      // });

      d3.selectAll('#venn tspan').attr('y', function(d) {
        if (d.label === 'Overlap') {
          console.log('in');
          return 205;
        }
        return 225;
      });

      // const yVal2 = d3.selectAll('#venn tspan').attr('y');
      // console.log('yVal2: ', yVal2);

      vennChart
        .selectAll('g')
        .on('mouseover', function(d) {
          // sort all the areas relative to the current item
          venn.sortAreas(vennChart, d);

          // Display a tooltip with the current size
          tooltip
            .transition()
            .duration(400)
            .style('opacity', 0.9)
            .style('position', 'absolute')
            .style('text-align', 'center')
            .style('background', '#333')
            .style('color', '#ddd')
            .style('padding-left', '20px')
            .style('padding-right', '20px')
            .style('border', '0px')
            .style('border-radius', '8px');
          tooltip.text(
            `${d.size.toLocaleString(undefined, {
              // minimumFractionDigits: 2,
              maximumFractionDigits: 0,
            })} ${d.label.toLowerCase().includes('overlap') ? 'Reach Overlap' : 'Reach'}`
          );

          // highlight the current path
          const selection = d3
            .select(this)
            .transition('tooltip')
            .duration(400);

          selection
            .select('path')
            .style('fill-opacity', d.sets.length === 1 ? 0.6 : 0.4)
            .style('stroke-width', 0.1)
            .style('stroke-opacity', 0.4);
        })

        .on('mousemove', function() {
          tooltip
            .style('position', 'absolute')
            .style('left', `${d3.event.pageX}px`)
            .style('top', `${d3.event.pageY - 28}px`);
        })

        .on('mouseout', function(d) {
          tooltip
            .transition()
            .duration(400)
            .style('opacity', 0);
          const selection = d3
            .select(this)
            .transition('tooltip')
            .duration(400);
          selection
            .select('path')
            .style('stroke-width', 0)
            .style('fill-opacity', d.sets.length === 1 ? 0.25 : 0.0)
            .style('stroke-opacity', 0);
        });

      // Custom labelp position
      // d3.selectAll('#venn text').attr('y', 225);
      // d3.selectAll('#venn text').attr('y', function(d) {
      //   console.log(d);
      //   return d.label.toLowerCase().includes('overlap') ? 2750 : 2250;
      //   // return 2250;
      // });
    },
    async fetchData() {
      const { advertiserId, payload } = this;
      const solutionId = this.activeSolutionId;
      this.dataLoading = true;
      const data = await appConsoleDataLayer.fetchCTVSummary(advertiserId, solutionId, payload);
      this.dataLoading = false;
      const vennData = data;
      // const vennData = {
      //   overallUniqueReach: 295172,
      //   incrementalCtvReach: 0.3378,
      //   uniqueCtvReach: 0.814,
      //   overallLinearReach: 220642,
      //   linearAndCtvOverlapReach: 17025,
      //   overallCtvReach: 91555,
      //   linearCvr: 0.015755,
      //   linearAndCtvOverlapCvr: 0.492647,
      //   ctvCvr: 0.047186,
      // };
      return vennData;
    },
    toggleIsVennExpanded() {
      this.isVennExpanded = !this.isVennExpanded;
      // this.isLeftNavCollapsed = this.isVennExpanded;
      // console.log('toggleIsVennExpanded: ', this.isVennExpanded);
    },
    toggleIsTableExpanded() {
      this.isTableExpanded = !this.isTableExpanded;
      // this.isLeftNavCollapsed = this.isTableExpanded;
      // console.log('toggleIsTableExpanded: ', this.isTableExpanded);
    },
  },
};
</script>
<style lang="scss" scoped>
.bdc_main_wrap.whero {
  width: 85vw;
  /* margin-top: 70px; */
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.body-content.fullmax .thw_content {
  max-width: 1400px;
}
.thw_content {
  max-width: 1400px;
  margin: auto;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 0px;
  position: relative;
  z-index: 11;
}
.sopened .thw_content {
  max-width: 1400px;
}
.internal-sub-nav {
  float: none;
  margin: 11px 20px 31px 5px;
  display: block;
}
.isn-item {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primarydark2);
  position: relative;
  cursor: pointer;
}

.isn-item.active {
  color: var(--primarydark);
}

.isn-item.active::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarycolor);
}

.isn-item:hover {
  color: var(--primarydark);
}

.isn-item:hover::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarydark2);
}

@media (max-width: 900px) {
  .bdc_top_panel_wrap {
    flex-wrap: wrap !important;
  }
}
.bdc_top_panel_wrap {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 3;
}
.expandContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  margin-top: 20px;
  margin-right: 5px;
  color: var(--primarylight);
  .expandIcon {
    transform: scale(0.8);
  }
}
.exportIcon {
  margin-right: 20px;
  color: var(--primarylight);
}
.vennContainer {
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);

  .vennHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    margin-left: 20px;
    font-weight: bold;
  }
  .venn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.tableContainer {
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    margin-left: 20px;
    font-weight: bold;
  }
  .overviewTable {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 20px;
    table {
      width: 95%;
    }
    th {
      padding: 14px 30px 14px 15px;
      color: var(--primarylight);
      background-color: #fbfbfb;
    }
    tr:nth-child(even) {
      background-color: #fbfbfb;
    }
    td {
      padding: 14px 30px 14px 15px;
      text-align: left;
    }
    table,
    th,
    td {
      border: 0.1px silver solid;
    }
    .col1 {
      text-align: left;
    }
  }
  .vennExpandIcon {
    cursor: pointer;
  }
  .tableExpandIcon {
    cursor: pointer;
  }
}
</style>
