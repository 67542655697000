var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bdc_top",class:_vm.isCampaignTableExpanded ? 'extHeader' : 'CollHeader'},[_c('div',{staticClass:"bdc_top_band",class:{
        hideTopNav: _vm.isCampaignTableExpanded,
        showTopNav: !_vm.isCampaignTableExpanded,
        'custom-bg-color': _vm.isMniAccount,
      },attrs:{"id":"top-filter-bar-container"}},[_c('div',{staticClass:"tfb-content-wrap",attrs:{"id":"top-filter-bar"}},[_c('div',{staticClass:"tfb-content"},[_c('div',[_c('div',{staticClass:"mdn-page-title-txt move-title",class:{ 'move-title': !_vm.isLeftNavCollapsed, 'custom-bg-title': _vm.isMniAccount }},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticClass:"text-right"},[_c('div',{staticClass:"tp_acct_wrap",class:_vm.isLeftNavCollapsed ? '' : 'move-acct-wrap'},[_c('switch-account',{class:_vm.isLeftNavCollapsed ? '' : 'flexi-dt-cont',attrs:{"show-full-view":_vm.showFullView,"show-accounts":_vm.showAccounts},on:{"toggle-account-switcher":_vm.toggleAccountSwitcher}})],1)])])])]),(!_vm.hideFilterBar)?_c('div',{staticClass:"sub_filters_bar sub_filters_wrap",class:[_vm.isCampaignTableExpanded ? 'zeroTop' : '', _vm.isMniAccount ? 'mniAccountStyle' : ''],attrs:{"id":"sub_filter_bar"}},[_c('div',{staticClass:"filter-and-date-tabs",class:{
          'move-filter-tab': !_vm.isLeftNavCollapsed,
          'cstm-mni-filter': _vm.isMniAccount,
        }},[_c('div',{staticClass:"sub_filters_left"},[(!_vm.isMniAccount)?_c('b-dropdown',{staticClass:"campaign-select",attrs:{"options":_vm.filters.campaignOptions,"header":"Campaigns","component-id":"campaignOptionDropdown","is-loading":_vm.isAdGroupLoading || _vm.filtersAppliedLoading,"allow-multi-select":true,"show-all":true,"disabled":_vm.disableCampaignFilter},on:{"dropdown-closed":_vm.onCloseCampaignDropdown,"selected-options":_vm.onSelectCampaign}}):_vm._e(),(!_vm.isMniAccount)?_c('b-dropdown',{staticClass:"ad-group-select",class:_vm.isAdGroupLoading ? 'disabled' : '',attrs:{"options":_vm.filters.adGroupOptions || _vm.adGroupOptions,"header":"Ad Group","is-loading":_vm.filtersApplied,"allow-multi-select":true,"show-all":true,"disabled":_vm.disableAdGroupFilter},on:{"dropdown-closed":_vm.onCloseAdGroupDropdown,"selected-options":_vm.onSelectAdGroups}}):_vm._e(),(!_vm.disablePixelFilter && !_vm.isMniAccount)?_c('b-dropdown',{staticClass:"pixel-select",class:_vm.isAdGroupLoading || _vm.isConversionDisabled ? 'disabled' : '',attrs:{"options":_vm.filters.pixelOptions || _vm.pixelOptions,"header":"Conversions","is-loading":_vm.filtersApplied,"allow-multi-select":true,"show-all":true},on:{"dropdown-closed":_vm.onCloseConversionsDropdown,"selected-options":_vm.onSelectPixels}}):_vm._e(),(_vm.isMniAccount)?_c('div',[_c('b-select-checkbox',{staticClass:"events-dd",attrs:{"header":"Media Type","icon-pos":"left","options":_vm.filters.mediaTypeOptions || _vm.mediaTypeOptions,"custom-select-all-text":'SELECT / DESELECT ALL',"apply-white-labeling":true,"disabled":_vm.isAdGroupLoading ||
                  _vm.title === 'MNI Motto // Custom Reports' ||
                  _vm.selectedDashboardTab === 'journeyanalytics'},on:{"dropdown-closed":_vm.onCloseMediaTypeDropdownMni,"selected-options":_vm.onSelectMediaTypesMni}})],1):_vm._e(),(_vm.isMniAccount)?_c('div',[_c('b-select-checkbox',{staticClass:"events-dd",attrs:{"header":"Campaigns","icon-pos":"left","options":_vm.filters.campaignOptions || _vm.campaignOptions,"custom-select-all-text":'SELECT / DESELECT ALL',"apply-white-labeling":true,"disabled":_vm.isAdGroupLoading ||
                  _vm.title === 'MNI Motto // Custom Reports' ||
                  _vm.selectedDashboardTab === 'journeyanalytics'},on:{"dropdown-closed":_vm.onCloseCampaignDropdownMni,"selected-options":_vm.onSelectCampaignMni}})],1):_vm._e(),(_vm.isMniAccount)?_c('div',[_c('b-select-checkbox',{staticClass:"events-dd",attrs:{"header":"Lookback Window (days)","icon-pos":"left","options":_vm.daysToconvertOptions,"apply-white-labeling":true,"multi-select-enabled":false,"disabled":_vm.isAdGroupLoading || _vm.title === 'MNI Motto // Custom Reports',"disable-tool-tip":true},on:{"selected-options":_vm.onSelectDayToConvert}})],1):_vm._e(),_c('div',{staticClass:"ftop-icons fltr-btn tt",class:{
              disabled:
                _vm.disableAdvancedFilter ||
                _vm.title === 'MNI Motto // Custom Reports' ||
                _vm.selectedDashboardTab === 'journeyanalytics',
            },on:{"click":_vm.handleShowAdvancedFilters}},[_c('div',{staticClass:"ftop-icon"},[_c('font-awesome-icon',{staticClass:"fa-regular fa-filter btn-icon",attrs:{"icon":['far', 'filter']}})],1),(_vm.appliedFilterCount > 0)?_c('div',{staticClass:"applied-cnt"},[_c('span',{staticClass:"acnum"},[_vm._v(_vm._s(_vm.appliedFilterCount))])]):_vm._e(),_vm._m(0)])],1),_c('div',{staticClass:"sub_filters_right",class:_vm.isLeftNavCollapsed ? '' : 'move-rightside-tab'},[_c('div',{staticClass:"wrap4icon icon-cal ical2",class:{ disabled: _vm.disableDateFilter },staticStyle:{"top":"0px","float":"right"}},[(!_vm.dateLoading)?_c('div',{staticClass:"drp-wrap"},[_c('div',{staticClass:"compare-label b-datepicker-container"},[_c('b-datepicker',{class:_vm.isLeftNavCollapsed ? '' : 'move-rightside-datepicker',attrs:{"week1":[_vm.dates.startDate, _vm.dates.endDate],"week2":[_vm.dates.compareStartDate, _vm.dates.compareEndDate],"campaign-options":_vm.campaignOptions,"active-solution-id":_vm.activeSolutionId,"compare-with-text":"vs:","hide-compare-with":_vm.isMniAccount},on:{"change":_vm.bDatepickerChanges}})],1)]):_vm._e()])])])]):_vm._e()]),(_vm.isMniAccount)?_c('advanced-filters2',{key:`JSON.stringify(advancedFilters)|${_vm.selectedDashboardTab}`,attrs:{"show-advanced-filters":_vm.showAdvancedFilters,"is-advance-filter-loading":_vm.isAdvanceFilterLoading,"initial-filters":_vm.advancedFilters,"active-solution-id":_vm.activeSolutionId,"filters-to-hide":_vm.filtersToHide},on:{"on-close":_vm.onCloseAdvanceFilters,"on-apply":_vm.onAdvanceFilterApply}}):_c('advanced-filters',{key:`JSON.stringify(advancedFilters)|${_vm.selectedDashboardTab}`,attrs:{"show-advanced-filters":_vm.showAdvancedFilters,"is-advance-filter-loading":_vm.isAdvanceFilterLoading,"initial-filters":_vm.advancedFilters,"active-solution-id":_vm.activeSolutionId,"filters-to-hide":_vm.filtersToHide},on:{"on-close":_vm.onCloseAdvanceFilters,"on-apply":_vm.onAdvanceFilterApply}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ttip-wrap"},[_c('div',{staticClass:"ttip"},[_vm._v("Advanced Filters")])])
}]

export { render, staticRenderFns }