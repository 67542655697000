<template>
  <div>
    <div class="pnl_itm_title">
      <div class="pnl_itm_title_txt active">Top Performers</div>
      <div class="pnl_title_right">
        <div class="pnl_menu_btn" @click="handleExportClick">
          <font-awesome-icon :icon="['fas', 'arrow-down-to-line']" />
        </div>
      </div>
    </div>
    <div v-if="!loading" class="panel_scroll_wrap" style="height: 393px;">
      <div class="tg-wrap">
        <table id="tg-wqhPh" class="tg hm_table">
          <thead>
            <tr>
              <div class="sm_pnl_subtop_wrap">
                <div
                  v-if="!hasMultiLevelDimensions"
                  class="sm_pnl_subtop sbt_left"
                  style="width: 60%"
                >
                  <b-dropdown-flat
                    :options="dimensionOptions"
                    :show-option-with-icon="true"
                    :allow-multi-select="true"
                    :show-all="false"
                    component-id="dimensionOptionsDropdown"
                    @selected-options="onSelectDimension"
                  />
                </div>
                <div
                  v-if="hasMultiLevelDimensions"
                  class="sm_pnl_subtop sbt_left"
                  style="width: 60%"
                >
                  <b-dropdown-multilevel
                    :options="dimensionOptions"
                    :allow-multi-select="true"
                    :show-all="false"
                    component-id="dimensionOptionsDropdown"
                    @selected-options="onSelectDimension"
                  />
                </div>
                <div class="sm_pnl_subtop sbt_right" style="width: 38%">
                  <b-dropdown-flat
                    :key="JSON.stringify(metricOptions)"
                    :options="metricOptions"
                    component-id="metricOptionsDropdown"
                    @on-change="onSelectMetric"
                  />
                </div>
              </div>
            </tr>
          </thead>
          <tbody
            v-if="!loading && rows.length"
            :id="tooltipId"
            ref="table-scroll-top-perform"
            class="top-performance-tbody  table-scroll-top-perform"
          >
            <tr v-for="(row, i) in sortedRows" :key="i" :style="{ position: 'relative' }">
              <td
                class="tg-0lax tg-type"
                :class="{
                  'tg-sort-header': i === 0,
                  'tg-sort-asc': i === 0 && iconSortDirection === 'desc',
                  'tg-sort-desc': i === 0 && iconSortDirection === 'asc',
                }"
                :style="{ width: '67px' }"
                @click="i === 0 ? iconClickHandler() : null"
              >
                <font-awesome-icon :icon="['fas', row.icon]" />
              </td>
              <td
                :ref="`${i}_metadata`"
                class="tg-0lax tg-txt"
                :class="{
                  'tg-sort-header': i === 0,
                  'tg-sort-asc': i === 0 && sortBy === 'name' && sortDirection === 'desc',
                  'tg-sort-desc': i === 0 && sortBy === 'name' && sortDirection === 'asc',
                }"
                @click="i === 0 ? clickHandler('name') : null"
                @mouseover="(el) => handleMouseOver(el, row.name, `${i}_metadata`)"
                @mouseleave="handleMouseLeave"
              >
                {{ row.name }}
              </td>
              <td
                class="tg-0lax tg-val rt"
                :class="{
                  'tg-sort-header': i === 0,
                  'tg-sort-asc': i === 0 && sortBy === 'value' && sortDirection === 'desc',
                  'tg-sort-desc': i === 0 && sortBy === 'value' && sortDirection === 'asc',
                }"
                :style="{ width: '114px' }"
                @click="i === 0 ? clickHandler('value') : null"
              >
                {{ formatNumber(row.value, formatType, decimals) }}
              </td>
            </tr>
          </tbody>
          <tbody
            v-if="!loading && !rows.length"
            ref="table-scroll-top-perform"
            class="top-performance-tbody  table-scroll-top-perform"
          >
            <tr>
              <td class="tg-0lax tg-txt lft">
                No data is available to show
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-tooltip
        :tooltip-text="activeEl.text"
        :visible-style="{ ...activeEl.style, zIndex: 9999 }"
      />
    </div>
    <b-loading-spinner v-if="loading" class="txt-center" />
  </div>
</template>
<script>
import { uuid } from '@/helpers/global/misc-helpers';
import BDropdownFlat from '~/components/elements/b-dropdown-flat.vue';
import formatNumber from '~/util/numeral';
import { DECIMAL_PERCENTAGE, CURRENCY } from '~/constant';
import BTooltip from '~/components/elements/b-tooltip.vue';
import BDropdownMultilevel from './elements/b-dropdown-multilevel.vue';

export default {
  components: {
    BDropdownFlat,
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    BTooltip,
    BDropdownMultilevel,
  },
  props: {
    dimensionOptions: {
      type: Array,
      required: true,
    },
    metricOptions: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    sortInfinityValues: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  data() {
    let selectedDimensions = [];
    const hasMultiLevelDimensions =
      this.dimensionOptions.filter((o) => o?.children?.length)?.length > 0;
    if (hasMultiLevelDimensions) {
      this.dimensionOptions.forEach((opt) => {
        if (opt.children && opt.children.length) {
          opt.children.forEach((o) => {
            if (o.checked) {
              selectedDimensions.push(o.value.toLowerCase());
            }
          });
        } else if (opt.checked) {
          selectedDimensions.push(opt.value.lowerCase());
        }
      });
    } else {
      selectedDimensions = this.dimensionOptions?.map((o) => o.value.toLowerCase()) || [];
    }
    let selectedMetric = '';
    if (this.metricOptions?.length) {
      const defaultSelectedMetric = this.metricOptions.find((o) => o.selected);
      selectedMetric = defaultSelectedMetric?.id || this.metricOptions[0].id;
    }

    return {
      sortBy: 'value',
      sortDirection: 'desc',
      enableGroupSort: false,
      iconSortDirection: 'asc',
      selectedDimensions,
      hasMultiLevelDimensions,
      selectedMetric,
      formatNumber,
      activeEl: { style: { display: 'none', visibility: 'hidden' }, text: '', index: -1 },
      tooltipId: uuid(),
    };
  },
  computed: {
    sortedRows() {
      if (this.enableGroupSort) {
        return this.groupSortRows(this.rows);
      }
      return this.sortRows(this.rows);
    },
    decimals() {
      return ['ctr', 'cpc', 'cpa'].includes(this.selectedMetric) ? '00' : '0';
    },
    formatType() {
      return ['cpc', 'cpa'].includes(this.selectedMetric) ? CURRENCY : DECIMAL_PERCENTAGE;
    },
  },
  watch: {
    metricOptions(newMetricOptions) {
      this.selectedMetric = '';
      if (newMetricOptions?.length) {
        const defaultSelectedMetric = newMetricOptions.find((o) => o.selected);
        this.selectedMetric = defaultSelectedMetric?.id || newMetricOptions[0].id;
      }
    },
  },
  methods: {
    sortRows(rows) {
      let infinityRows = [];
      let sortedRows;
      if (this.sortInfinityValues) {
        sortedRows = JSON.parse(JSON.stringify(rows));
      } else {
        // 1. Separate out rows with infinity value
        infinityRows = rows.filter((r) => r[this.sortBy] === 'NA');
        sortedRows = rows.filter((r) => r[this.sortBy] !== 'NA');
      }

      // 2. Sort the remaining rows
      sortedRows.sort((a, b) => {
        let result = 1;
        const valueA = this.sortInfinityValues && a[this.sortBy] === 'NA' ? 0 : a[this.sortBy];
        const valueB = this.sortInfinityValues && b[this.sortBy] === 'NA' ? 0 : b[this.sortBy];

        if (this.sortDirection === 'desc') {
          result = -1;
        }
        if (valueA < valueB) {
          return -1 * result;
        }
        if (valueA > valueB) {
          return 1 * result;
        }
        return 0;
      });

      if (!this.sortInfinityValues) {
        // 3. Add rows with infinity value back to the sorted rows so that they always appear at bottom
        infinityRows.forEach((r) => sortedRows.push(r));
      }

      return sortedRows;
    },
    groupSortRows(rows) {
      const finalRows = [];

      // 1. Group rows by dimension
      const groupedRows = rows.reduce((r, a) => {
        r[a.dimension] = r[a.dimension] || [];
        r[a.dimension].push(a);
        return r;
      }, Object.create(null));

      // 2. Fetch keys from groupedRows and sort keys
      const sortedKeys = Object.keys(groupedRows);
      sortedKeys.sort((a, b) => {
        let result = 1;

        if (this.iconSortDirection === 'desc') {
          result = -1;
        }
        if (a < b) {
          return -1 * result;
        }
        if (a > b) {
          return 1 * result;
        }
        return 0;
      });

      // 3. Loop through sorted keys
      sortedKeys.forEach((key) => {
        const rowsInGroup = groupedRows[key];

        // 4. Sort rows in each group
        const sortedRows = this.sortRows(rowsInGroup);

        // 5. Add sorted rows to final array
        finalRows.push(...sortedRows);
      });

      // 6. Return final array
      return finalRows;
    },
    handleMouseOver(el, text, refKey) {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      const elementPos = this.$refs[refKey][0];
      let tableElement;
      if (isSafari) {
        tableElement = this.$refs['table-scroll-top-perform'];
      } else {
        tableElement = document.getElementsByClassName('table-scroll-top-perform');
      }
      if (!elementPos || !tableElement) {
        return;
      }

      const style = {
        display: 'block',
        opacity: '0.96',
        'max-width': '500px',
        width: 'max-content',
      };
      if (isSafari) {
        style.top = `${elementPos.offsetParent.offsetTop - tableElement.scrollTop - 10}px`;
        style.left = `${elementPos.offsetLeft - tableElement.scrollLeft + 60}px`;
        style.visibility = 'visible';
      } else {
        style.top = `${elementPos.offsetParent.offsetTop - tableElement[0].scrollTop - 10}px`;
        style.left = `${elementPos.offsetLeft - tableElement[0].scrollLeft + 60}px`;
        style.visibility = 'visible';
      }
      this.activeEl = {
        style,
        text,
      };
    },
    handleMouseLeave() {
      this.activeEl = { style: { display: 'none' }, text: '' };
    },
    handleExportClick() {
      const header = ['dimension', 'name', this.selectedMetric];
      const lines = [];
      this.sortedRows.forEach((row) => {
        lines.push([row.dimension, row.name, row.value]);
      });

      const fileName = `export_${this.selectedMetric}_top_performers.csv`;
      const csvData = [header, ...lines];
      this.$emit('on-export', fileName, csvData);
    },
    clickHandler(col) {
      this.enableGroupSort = false;
      this.iconSortDirection = 'asc';
      if (this.sortBy === col) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortBy = col;
        this.sortDirection = 'asc';
      }
    },
    iconClickHandler() {
      this.enableGroupSort = true;
      this.sortBy = 'value';
      this.sortDirection = 'desc';
      this.iconSortDirection = this.iconSortDirection === 'asc' ? 'desc' : 'asc';
    },
    onSelectDimension(options) {
      if (this.hasMultiLevelDimensions) {
        const selectedDim = [];
        options.forEach((opt) => {
          if (opt.selected) {
            if (opt.children && opt.children.length) {
              const selectedChildIds = opt.children.filter((c) => c.selected).map((c) => c.id);
              if (selectedChildIds) {
                selectedDim.push(...selectedChildIds);
              }
            } else {
              selectedDim.push(opt.id);
            }
          }
        });
        this.selectedDimensions = selectedDim;
      } else {
        this.selectedDimensions = options.filter((o) => o.checked).map((o) => o.id);
      }
      this.$emit('on-options-change', this.selectedDimensions, this.selectedMetric);
    },
    onSelectMetric(option) {
      this.selectedMetric = option;
      this.$emit('on-options-change', this.selectedDimensions, this.selectedMetric);
    },
  },
};
</script>
<style lang="scss" scoped>
.pnl_itm_title {
  border-bottom: 1px solid #e0e5ee;
  padding-bottom: 0px;
  white-space: nowrap;
}

.pnl_itm_title_txt {
  display: inline-block;
  position: relative;
  border-bottom: 0px solid;
  padding-bottom: 15px;
  top: 2px;
  z-index: 9;
  color: var(--primarylight);
  font-size: 1.02em;
  font-weight: 600;
  margin-right: 25px;
  cursor: pointer;
}
.pnl_itm_title_txt:hover {
  color: var(--primarydark);
}
.pnl_itm_title_txt.active {
  display: inline-block;
  position: relative;
  border-bottom: 3px solid var(--primarycolor);
  padding-bottom: 15px;
  top: 2px;
  z-index: 9;
  font-weight: 600;
  color: var(--primarydark);
}

/*small panel under title dropdown*/
.sm_pnl_subtop {
  margin: 0px;
  vertical-align: top;
}

.sm_pnl_subtop_wrap {
  border-bottom: 1px solid #e4e6ec;
  margin-bottom: 0px;
  white-space: nowrap;
}

::v-deep .sm_pnl_subtop .dropdown,
::v-deep .sm_pnl_subtop .multiselect-dropdown {
  width: 100%;
  max-width: 100%;
  min-width: auto;
  border: 0px;
  border-radius: 0px;
  margin-bottom: 0px;
  vertical-align: bottom;
  font-size: 0.93em;
}
::v-deep .sm_pnl_subtop .dropdown .select,
::v-deep .sm_pnl_subtop .multiselect-dropdown .select {
  padding: 9px 20px 9px 1px;
}
::v-deep .sm_pnl_subtop .dropdown .select:focus-visible,
::v-deep .sm_pnl_subtop .multiselect-dropdown .select:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
  outline: none;
}

::v-deep .sm_pnl_subtop .dropdown:focus-visible,
::v-deep .sm_pnl_subtop .multiselect-dropdown:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
  outline: none;
}

::v-deep .sm_pnl_subtop .droparw {
  right: inherit;
  margin-left: 8px;
}

.sbt_right {
  display: inline-block;
  width: 49%;
}
.sbt_left {
  display: inline-block;
  width: 49%;
}
::v-deep .sm_pnl_subtop .dropdown.rt .select {
  padding: 9px 30px 9px 10px;
}
::v-deep .dropdown.rt .dropdown-menu li,
::v-deep .dropdown.rt .dropdown-menu-multi li {
  padding: 5px 20px 5px 10px;
}

::v-deep .sbt_left .droparw {
  right: inherit;
  margin-left: 8px;
}

::v-deep .sbt_left .top-span {
  margin-right: 10px;
}

::v-deep .sbt_right .dropdown {
  text-align: right;
}

::v-deep .sbt_right .dropdown .select {
  padding: 9px 0px 9px 1px;
}
::v-deep .sbt_right .dropdown .dropdown-menu li,
::v-deep .sbt_right .dropdown .dropdown-menu-multi li {
  padding: 5px 20px 5px 10px;
}

::v-deep .sbt_right .dropdown .dropdown-menu li.dropdown-content-checked .norm-option-item {
  width: 100%;
}

.sbm_i {
  margin-left: 8px;
  margin-right: 4px;
}

::v-deep .sm_pnl_subtop.sbt_right .dropdown .dropdown-menu-multi {
  min-width: 180px;
  margin-left: -54px;
  text-align: left;
}

::v-deep .sm_pnl_subtop.sbt_right .dropdown-menu-multi .mdrp-sub-itms .drop-ck {
  left: 17px;
  top: 11px;
}
.sm_pnl_subtop.sbt_right .mdrp-sub {
  padding: 8px 9px 8px 40px !important;
}

.sm_pnl_subtop .subtop_title_txt {
  font-size: 13px;
  font-weight: 500;
  color: var(--primarydark2);
  padding: 9px 10px;
  vertical-align: top;
}
.sm_pnl_subtop.sbt_right .subtop_title_txt {
  text-align: right;
}
.top-performance-tbody {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 353px;
  overflow-x: hidden;
}

.panel_scroll_wrap {
  overflow: hidden;
  height: 100%;
  position: relative;
  &.table-scroll {
    -webkit-overflow-scrolling: touch;
    & tr:hover {
      background-color: #f0f1f5;
    }
    & tr:nth-child(even) {
      box-shadow: inset 0px 0px 90px rgba(0, 0, 0, 0.04);
    }
  }
}
.hm_table {
  border: 0px solid #fff;
  width: 100%;
}
.hm_table tr {
  display: table;
  width: 100%;
  text-align: left;
  table-layout: fixed;
}
.hm_table {
  .firstCol {
    width: 240px;
  }
}
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-color: var(--primarydark2);
  border-style: solid;
  border-width: 0px;
  font-size: 13px;
  font-size: 0.86em;
  overflow: hidden;
  padding: 13px 5px;
  word-break: normal;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// .tg td:hover {
//   white-space: normal;
//   overflow: visible;
//   text-overflow: inherit;
// }

.full-width .tg th {
  padding: 14px 30px 14px 15px;
}
.full-width .tg td {
  padding: 13px 30px 13px 15px;
}
.tg th.wdrops {
  padding: 9px 5px 1px;
}
.tg-ul38 {
  position: -webkit-sticky;
  position: sticky;
  text-align: left;
  top: -1px;
  vertical-align: top;
  will-change: transform;
  z-index: 2;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px -6px 0px -5px #d4e1e8 !important;
}
.tg-wrap tr:hover {
  background-color: #f0f1f5;
}
.tg-wrap tr:nth-child(even) {
  box-shadow: inset 0px 0px 90px rgba(0, 0, 0, 0.04);
}

.tg .tg-0lax {
  text-align: left;
  vertical-align: top;
}
.tg-sort-header::-moz-selection {
  background: 0 0;
}
.tg-sort-header::selection {
  background: 0 0;
}
.tg-sort-header {
  cursor: pointer;
}
.tg-sort-header:after {
  content: '';
  margin: 7px 0px 0px 5px;
  border-width: 0 5px 5px;
  border-style: solid;
  border-color: #404040 transparent;
  visibility: hidden;
  position: absolute;
}
.tg-sort-header:hover:after {
  visibility: visible;
}
.tg-sort-asc:after,
.tg-sort-asc:hover:after,
.tg-sort-desc:after {
  visibility: visible;
  opacity: 0.4;
}
.tg-sort-desc:after {
  border-bottom: none;
  border-width: 5px 5px 0;
}
@media screen and (max-width: 767px) {
  .tg {
    width: auto !important;
  }
  .tg col {
    width: auto !important;
  }
  .tg-wrap {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.rt {
  text-align: right !important;
}
.ctr {
  text-align: center !important;
}
.lft {
  text-align: left !important;
}
.tg-0lax i {
  font-size: 14px;
}

.one-thirds .tg .tg-0lax {
  max-width: 15vw;
}

@media (max-width: 1350px) {
  .one-thirds .tg .tg-0lax {
    max-width: 13vw;
  }
}

.one-thirds .tg td {
  padding: 10px 5px;
}

.two-thirds .tg .tg-0lax {
  max-width: 10vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
}

.wdrops.tg-sort-header::after {
  margin: 15px 0px 0px 0px;
}

.tb-expand {
  position: absolute;
  top: 4px;
  right: 10px;
  font-size: 11px;
  color: var(--primarylight);
  z-index: 9;
}

.tb-expand:hover {
  color: var(--primarycolor);
}
.expd {
  height: calc(100vh - 90px) !important;
  max-height: calc(100vh - 90px) !important;
}

.pnl_title_right {
  display: inline-block;
  width: 250px;
  text-align: right;
  float: right;
  margin-top: -6px;
  position: relative;
}
.one-thirds .pnl_title_right {
  width: 30px;
}

.pnl_menu_btn {
  display: inline-block;
  position: relative;
  vertical-align: top;
  padding: 7px;
  margin-right: -7px;
  font-size: 16px;
  color: var(--primarylight);
}
.pnl_menu_btn:hover {
  color: var(--primarycolor);
}
</style>
